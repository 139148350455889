.login-sections-container {
    width: 100%;
    display: flex;
    overflow: hidden;
}
.login-section-left {
    width: 50%;
    height: 100vh;   
    display: flex;
    flex-direction: column;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    background: #014373;
    font-weight: bold;
    animation: left-to-right 3s;
}
.login-logo {
    width: 40%;
    animation: left-to-right 3s;
    position: absolute;
}
.login-section-right {
    width: 50%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.login-card {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 1rem;
    animation: right-to-left 3s;;
}
.login-card .login-text {
    color: #02528a;
    margin: 1rem;
}

@keyframes right-to-left {
    from {
        opacity: 0;
        transform: translateX(600px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}
@keyframes left-to-right {
    from {
        opacity: 0;
        transform: translateX(-600px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}