.sider-style{
    text-align: 'center';
    background-color: blue;
}
.upload-file-logo {
    width: 80%;
    height: 10%;
    margin-bottom: 80%;
}
.upload-file-card {
    background: blue;
}
.sider-content{
    
}